import {
  CaretDownOutlined,
  CaretUpOutlined,
  CloseCircleFilled,
  DoubleLeftOutlined,
  DoubleRightOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from '@ant-design/icons'
import { unwrapResult } from '@reduxjs/toolkit'
import { Dropdown, Layout, Menu, Modal } from 'antd'
import React, {
  ReactElement,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Link,
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom'
import {
  ApplicationType,
  removeCredentials,
  selectTenantName,
  selectUserId,
  selectUserName,
} from '../app/applicationSlice'
import { RootDispatch } from '../app/store'
import { NotFound } from '../compnents/dummy/NotFound'
import Icon, {
  LogoFilled,
  LogoutOutlined,
  RefreshFilled,
  UpgradeOutlined,
  UserHomeOutlined,
} from '../compnents/icons/Icons'
import { AccountManagementList } from '../features/accountManagement/accountManagement'
import { EditUserMessageModal } from '../features/accountManagement/accountMessageModal'
import { ChecklistManagementList } from '../features/checklistManagement/checkListManagement'
import { DepartmentManagementList } from '../features/DepartmentManagement/DepartmentManagement'
import { DoctorManagementList } from '../features/DoctorManagement/DoctorManagement'
import { FreePatientDetailListMain } from '../features/freePatients/detailMain/detail'
import { FreePatientList } from '../features/freePatients/freePatients'
import { AddOrEditHealthContent } from '../features/health/addOrEditHealthContent'
import { HealthList } from '../features/health/health'
import { HealthHotStatisticsList } from '../features/health/healthHotStatistics'
import { HospitalIntroduce } from '../features/hospitalIntroduce/hospitalIntroduce'
import { MedicalGuideAddOrEditDetail } from '../features/medicalGuide/addOrEditDetail'
import { MedicalGuideList } from '../features/medicalGuide/medicalGuide'
import { PatientsManagementList } from '../features/patientsManagement/patientsManagement'
import { UserManagementList } from '../features/userManagement/userManagement'
import { Menu as MenuModel } from '../models/menu'
import { ThemeContext } from '../theme/ThemeContext'
import { isChrome } from '../utils/SystemUtils'
import { MemberList } from '../features/member/member'
import { MemberHexiaoList } from '../features/memberHexiao/memberHexiao'
import { StatisticView } from '../features/statistic/statistic'
import styles from './Layout.module.css'
import {
  getMenus,
  removeBreadcrumbs,
  selectAppShadeIsModal,
  selectAppShadeTipText,
  selectBreadcrumbs,
  selectCurrentRoute,
  selectFixedMenus,
  selectMenus,
  setAppShadeIsModal,
  setAppShadeTipText,
  traceHint,
  traceRoute,
  untraceRoute,
} from './layoutSlice'
import { IntegralDetailList } from '../features/member/integralDetail/integralDetail'
import { AppointmentList } from '../features/appointmentList/appointmentList'
import { DivisionManagementList } from '../features/divisionManagement/divisionManagement'
import { WorkOrderApprovalList } from '../features/workOrderApproval/workOrderApproval'
import { WorkOrderTemplateList } from '../features/workOrderTemplate/workOrderTemplate'
import { ReaMedicalGuideWebViewList } from '../features/medicalGuideWebViewList/medicalGuideWebViewList'
const { Header, Content, Sider } = Layout

const { SubMenu } = Menu

export const DefaultLayout = (): ReactElement => {
  const theme = useContext(ThemeContext)

  const location = useLocation()

  const ref = useRef<HTMLDivElement>(null)

  const history = useHistory()

  const tenantName = useSelector(selectTenantName)

  const userName = useSelector(selectUserName)

  const menus = useSelector(selectMenus)

  const fixedBreadcrumbs = useSelector(selectFixedMenus)

  const breadcrumbs = useSelector(selectBreadcrumbs)

  const currentRoute = useSelector(selectCurrentRoute)

  const dispatch = useDispatch<RootDispatch>()

  const [collapsed, setCollapsed] = useState(false)

  const [overlayVisible, setOverlayVisible] = useState(false)

  const [isOverMenu, setIsOverMenu] = useState(false)

  const [isModalVisible, setIsModalVisible] = useState<any>(false)

  const userId = useSelector(selectUserId)

  const isTipTetx = useSelector(selectAppShadeTipText)

  const shadeIsVisible = useSelector(selectAppShadeIsModal)

  useEffect(() => {
    console.log(shadeIsVisible, 'shadeIsVisible')
  }, [shadeIsVisible])

  useEffect(() => {
    if (!shadeIsVisible) {
      dispatch(setAppShadeTipText('请稍后~~~'))
    } else {
      setTimeout(() => {
        dispatch(setAppShadeIsModal(false))
        dispatch(setAppShadeTipText('请稍后~~~'))
      }, 20000)
    }
  }, [shadeIsVisible])

  useEffect(() => {
    dispatch(getMenus())
  }, [dispatch])

  useEffect(() => {
    if (currentRoute) {
      let path = currentRoute.path
      if (currentRoute.query) {
        path = `${path}?${currentRoute.query}`
      }
      history.push(path, currentRoute.state)
    }
  }, [dispatch, history, currentRoute])

  useEffect(() => {
    if (
      currentRoute &&
      fixedBreadcrumbs.length > 0 &&
      currentRoute.path !== fixedBreadcrumbs[0].path
    ) {
      setCollapsed(true)
    } else if (!currentRoute && fixedBreadcrumbs.length > 0) {
      dispatch(traceHint(location.pathname))
    }
  }, [currentRoute, location.pathname, fixedBreadcrumbs])

  const overlay = (
    <Menu>
      {/* <Menu.Item className={styles.upgrade}>
        <UpgradeOutlined />
        <div className={styles.version}>
          到期时间：{appEndTime?.format('YYYY-MM-DD')}
        </div>
      </Menu.Item> */}
      <Menu.Item
        className={styles.overlay}
        onClick={() => {
          setIsModalVisible(true)
        }}
      >
        <UserHomeOutlined />
        个人设置
      </Menu.Item>
      <Menu.Item
        className={styles.overlay}
        onClick={() => {
          Modal.confirm({
            title: '提示',
            content: '请确认是否继续退出登录？',
            onOk: () => {
              history.push('/')
              dispatch(removeCredentials())
              dispatch(removeBreadcrumbs())
            },
          })
        }}
      >
        <LogoutOutlined />
        退出登录
      </Menu.Item>
    </Menu>
  )

  console.log(menus, 'menu')
  const createMenu = (menu: MenuModel, hasIcon: boolean) => {
    const icon = collapsed ? (
      <div className={styles.stack}>
        <Icon icon={menu.icon} mode='normal' />
        <div>{menu.name}</div>
      </div>
    ) : (
      <Icon icon={menu.icon} />
    )
    return menu.subMenus.length !== 0 ? (
      <SubMenu
        key={menu.path}
        icon={hasIcon ? icon : undefined}
        title={menu.name}
        className={collapsed ? styles.menuCollapsed : styles.menu}
      >
        {menu.subMenus.map((sm) => createMenu(sm, false))}
      </SubMenu>
    ) : (
      <Menu.Item
        key={menu.path}
        icon={hasIcon ? icon : undefined}
        onClick={() => dispatch(traceRoute(menu))}
        className={
          hasIcon ? (collapsed ? styles.menuCollapsed : styles.menu) : undefined
        }
      >
        <Link to={menu.path}>{menu.name}</Link>
      </Menu.Item>
    )
  }

  return (
    <>
      <Layout
        style={{
          width: '100vw',
          height: '100vh',
        }}
      >
        <Sider
          collapsible
          collapsed={collapsed}
          onCollapse={setCollapsed}
          trigger={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          className={styles.sider}
          style={{
            overflow: 'hidden',
            height: '100vh',
            position: 'fixed',
            left: 0,
          }}
        >
          {collapsed ? (
            <LogoFilled className={styles.logo} />
          ) : (
            <div className={styles.name}>{tenantName}</div>
          )}
          <Menu
            defaultSelectedKeys={menus?.length ? [menus[0].path] : []}
            inlineIndent={32}
            onMouseEnter={() => setIsOverMenu(true)}
            onMouseLeave={() => setIsOverMenu(false)}
            onMouseMove={() => setIsOverMenu(true)}
            style={{
              overflow: isOverMenu ? (isChrome ? 'overlay' : 'auto') : 'hidden',
              flex: '1',
            }}
          >
            {menus.map((menu) => createMenu(menu, true))}
          </Menu>
        </Sider>
        <Layout
          style={{
            marginLeft: collapsed ? 80 : 200,
            display: 'flex',
            flexDirection: 'column',
            height: '100vh',
          }}
        >
          <Header className={styles.header}>
            <DoubleLeftOutlined
              className={styles.scroll}
              onClick={() => {
                ref.current?.scrollBy({ left: -80, behavior: 'smooth' })
              }}
            />
            <div ref={ref} className={styles.breadcrumb}>
              {fixedBreadcrumbs.map((m) => (
                <div
                  key={m.path}
                  className={
                    m.name === currentRoute?.name ? styles.current : styles.item
                  }
                  onClick={() => {
                    if (m.path !== currentRoute?.path) {
                      dispatch(traceRoute(m))
                      history.push(m.path)
                    }
                  }}
                >
                  {m.name}
                </div>
              ))}
              {breadcrumbs.map((m) => (
                <div
                  key={m.name}
                  className={
                    m.name === currentRoute?.name ? styles.current : styles.item
                  }
                  onClick={() => {
                    if (m.name !== currentRoute?.name) {
                      dispatch(traceRoute(m))
                    }
                  }}
                >
                  {m.name}
                  <CloseCircleFilled
                    style={{ fontSize: '0.75rem' }}
                    onClick={(e) => {
                      e.stopPropagation()
                      dispatch(untraceRoute(m))
                    }}
                  />
                </div>
              ))}
            </div>
            <DoubleRightOutlined
              className={styles.scroll}
              onClick={() => {
                ref.current?.scrollBy({ left: 80, behavior: 'smooth' })
              }}
            />
            <RefreshFilled
              style={{ marginLeft: 40, cursor: 'pointer', fill: theme.hic }}
              onClick={() => {
                history.go(0)
              }}
            />
            <Dropdown
              className={styles.user}
              overlay={overlay}
              visible={overlayVisible}
              onVisibleChange={(visible) => setOverlayVisible(visible)}
            >
              <a>
                {`${userName} `}
                {overlayVisible ? <CaretUpOutlined /> : <CaretDownOutlined />}
              </a>
            </Dropdown>
          </Header>
          <Content className={styles.content}>
            <Switch>
              {/* 义诊列表 */}
              <Route exact path={['/home', '/admin/freePatient']}>
                <FreePatientList />
              </Route>
              {/* 就医指南 */}
              <Route exact path={'/admin/medicalGuideList'}>
                <MedicalGuideList />
              </Route>
              {/* 就医指南详情编辑 */}
              <Route exact path={'/admin/medicalGuideList/addOrEditDetail'}>
                <MedicalGuideAddOrEditDetail />
              </Route>
              {/* 医院介绍 */}
              <Route exact path={'/admin/hospitalIntroduce'}>
                <HospitalIntroduce />
              </Route>
              {/* 部门信息 */}
              <Route exact path={'/admin/departmentManagement'}>
                <DepartmentManagementList />
              </Route>
              {/* 医生信息 */}
              <Route exact path={'/admin/doctorManagement'}>
                <DoctorManagementList />
              </Route>
              {/* 义诊详情 */}
              <Route exact path={'/admin/freePatient/detail'}>
                <FreePatientDetailListMain />
              </Route>
              {/* 健康科普-文章管理 */}
              <Route exact path={'/admin/health'}>
                <HealthList />
              </Route>
              <Route exact path={'/admin/health/addOrEditHealth'}>
                <AddOrEditHealthContent />
              </Route>
              {/* 健康科普-热门科普统计 */}
              <Route exact path={'/admin/healthHotStatistics'}>
                <HealthHotStatisticsList />
              </Route>
              {/* 检查单管理 */}
              <Route exact path={'/admin/checklistManagement'}>
                <ChecklistManagementList />
              </Route>
              {/* 账号列表 */}
              <Route exact path={'/admin/userManagement'}>
                <UserManagementList />
              </Route>
              {/* 工单中心 工单审批*/}
              <Route exact path={'/admin/workOrderApproval'}>
                <WorkOrderApprovalList />
              </Route>
              {/* 工单中心 工单模板*/}
              <Route exact path={'/admin/workOrderTemplate'}>
                <WorkOrderTemplateList />
              </Route>
              {/* 就诊人管理 */}
              <Route exact path={'/admin/patientsManagement'}>
                <PatientsManagementList />
              </Route>
              {/* 账号管理 */}
              <Route exact path={'/admin/accountManagement'}>
                <AccountManagementList />
              </Route>
              {/* 部门管理 */}
              <Route exact path={'/admin/divisionManagement'}>
                <DivisionManagementList />
              </Route>
              {/* 会员管理 */}
              <Route exact path={'/admin/memberList'}>
                <MemberList />
              </Route>
              {/* 积分明细 */}
              <Route exact path={'/admin/memberList/integralDetail'}>
                <IntegralDetailList />
              </Route>
              {/* 核销记录 */}
              <Route exact path={'/admin/memberHexiaoList'}>
                <MemberHexiaoList />
              </Route>
              {/* 预约管理 */}
              <Route exact path={'/admin/appointment'}>
                <AppointmentList />
              </Route>
              {/* 统计分析 */}
              <Route exact path={'/admin/statistic'}>
                <StatisticView />
              </Route>
              {/* 中药煎配 */}
              <Route path={'/admin/medicalGuideWebViewList'}>
                <ReaMedicalGuideWebViewList/>
              </Route>
              <Redirect from='*' to='/admin/freePatient'></Redirect>
              <Route path='*'>
                <NotFound />
              </Route>
            </Switch>
          </Content>
        </Layout>
      </Layout>
      {shadeIsVisible ? (
        <div className={styles.back}>
          <p className={styles.tip}>{isTipTetx}</p>
        </div>
      ) : (
        <></>
      )}
      <EditUserMessageModal
        visible={isModalVisible}
        id={userId}
        onCancel={() => {
          setIsModalVisible(false)
        }}
        onOk={() => {
          setIsModalVisible(false)
        }}
      />
    </>
  )
}
