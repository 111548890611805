/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-01-04 14:44:28
 * @LastEditors: linxi
 * @LastEditTime: 2023-04-27 16:15:26
 */

import { API_PREFIX } from './constant'
import { ContentType, MultiPartFormData, Request } from './request'

export interface DoctorManagementListPrams {
  current: number
  size: number
  startTime?: string
  endTime?: string
}

// 列表
export function doctorManagementList(body: DoctorManagementListPrams): Request {
  return {
    url: `${API_PREFIX}/adminDoctor/detail`,
    method: 'POST',
    body,
  }
}

export interface DoctorListPrams {
  current: number
  size: number
  startTime?: string
  endTime?: string
  param?: any
}

// 列表
export function doctorList(body: DoctorListPrams): Request {
  return {
    url: `${API_PREFIX}/adminDoctor/page`,
    method: 'POST',
    body,
  }
}

// 导出
export function getExportFn(
): Request {
  return {
    url: `${API_PREFIX}/adminDoctor/export`,
    method: 'GET',
    responseType: 'blob',
  }
}


// 详情
export function doctorManagementDetail(id: string): Request {
  return {
    url: `${API_PREFIX}/adminDoctor/target`,
    method: 'GET',
    params: { id },
  }
}

export interface DoctorManagementEditPrams {
  adeptAt?: string
  headPhoto?: string
  intro?: string
  id?: string
  jobTitle?: string
  flag?: any
}

// 保存
export function doctorManagementEditSubmit(
  body: DoctorManagementEditPrams
): Request {
  return {
    url: `${API_PREFIX}/adminDoctor/saveOrUpdate`,
    method: 'POST',
    body,
  }
}

export interface DoctorManagementUploadPrams {
  id?: string
  flag?: any
}

// 保存
export function doctorManagementUpload(
  params: DoctorManagementUploadPrams
): Request {
  return {
    url: `${API_PREFIX}/adminDoctor/changeDisabledFlg`,
    method: 'GET',
    params,
  }
}

// 在线问诊
export function onlineInquiryUploadFn(
  params: DoctorManagementUploadPrams
): Request {
  return {
    url: `${API_PREFIX}/adminDoctor/changeOnline`,
    method: 'GET',
    params,
  }
}
