import { SearchOutlined } from '@ant-design/icons'
import { unwrapResult } from '@reduxjs/toolkit'
import {
  Button,
  Col,
  Form,
  Input,
  notification,
  Row,
  Select,
  Space,
} from 'antd'
import React, {
  forwardRef,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootDispatch } from '../../app/store'
import { EditableList } from '../../compnents/list/EditableList'
import { ThemeContext } from '../../theme/ThemeContext'
import { columns } from './columns'
import {
  getDoctorManagementList,
  getDoctorManagementUpload,
  getExportData,
  onlineInquiryUpload
} from './doctorManagementSlice'
import { EditDetailModal } from './editDetailModal'
import { TabBar, TabPane } from '../../compnents/widgets/TabBar'
import { getDepartmentManagementList } from '../DepartmentManagement/departmentManagementSlice'

export interface DoctorManagementRef {
  refresh?: () => void
  tabKey?: any
}

interface DoctorManagementProps {
  tabKey?: any
}
const RealDoctorManagements = (
  { tabKey }: DoctorManagementProps,
  ref: React.Ref<DoctorManagementRef>
): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const [departments, setDepartments] = useState<any[]>([])

  const theme = useContext(ThemeContext)

  const [form] = Form.useForm()

  const [pageLoading, setPageLoading] = useState(false) //表格 loading

  const [isEditRemarkVisible, setIsEditRemarkVisible] = useState(false)

  const [isOnlineEditRemarkVisible, setIsOnlineEditRemarkVisible] = useState(false)

  const [id, setId] = useState<string>()

  const [idOnline, setIdOnline] = useState<string>()

  const [params, setParams] = useState<any>({
    current: 1,
    size: 10,
  })

  const [page, setPage] = useState({
    items: [],
    total: 0,
  })

  useEffect(() => {
    dispatch(getDepartmentManagementList({ current: 1, size: 1000 }))
      .then(unwrapResult)
      .then((ds: any) => setDepartments(ds?.records))
      .catch(() => {
        // do nothing
      })
  }, [dispatch])

  const getPage = () => {
    setPageLoading(true)
    console.log(params, 'params')
    dispatch(
      getDoctorManagementList({
        ...params,
        current: params.current,
        size: params.size,
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        setPage({
          items: res.records,
          total: res.total,
        })
      })
      .finally(() => setPageLoading(false))
  }

  useEffect(() => {
    getPage()
  }, [params, tabKey])

  
  const exportList = () => {
    dispatch(getExportData())
      .then(unwrapResult)
      .then((res: any) => {
        const content = res
        const blob = new Blob([content])
        const fileName = '医生挂号费.xlsx'
        const selfURL = window[window.webkitURL ? 'webkitURL' : 'URL']
        const elink = document.createElement('a')
        if ('download' in elink) {
          elink.download = fileName
          elink.style.display = 'none'
          elink.href = selfURL['createObjectURL'](blob)
          document.body.appendChild(elink)
          // 触发链接
          elink.click()
          selfURL.revokeObjectURL(elink.href)
          document.body.removeChild(elink)
        } else {
          navigator.msSaveBlob(blob, fileName)
        }
      })
      .then(() => {
        notification.success({
          message: '导出成功',
          duration: 3,
        })
      })
      .catch((res: any) => {
        notification.success({
          message: '导出失败',
          duration: 3,
        })
      })
  }


  return (
    <TabBar style={{ margin: '10px 20px' }}>
      <TabPane key='0' tab='医生管理'>
        <Col
          style={{
            height: 'calc(100% - 30px)',
            overflow: 'hidden',
            position: 'relative',
            display: 'flex',
            margin: ' 10px 0',
            flexDirection: 'column',
          }}
        >
          <Col
            style={{
              flex: 1,
              backgroundColor: theme.pn,
              borderRadius: 10,
              padding: '0px 15px 0 15px',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Form
              form={form}
              autoComplete='off'
              onFinish={(values) => {
                setParams({ ...params, ...values, current: 1 })
              }}
            >
              <Row
                align='middle'
                justify='space-between'
                style={{ marginTop: 10, marginBottom: 20 }}
              >
                <Space>
                  <Form.Item name='param' noStyle>
                    <Input
                      placeholder='医生名称'
                      style={{ width: 200 }}
                      prefix={<SearchOutlined color={theme.tc3} />}
                      allowClear
                      onChange={form.submit}
                    />
                  </Form.Item>
                  <Form.Item noStyle name='departmentId'>
                    <Select
                      style={{ width: '10rem' }}
                      placeholder='科室'
                      allowClear
                      onChange={form.submit}
                    >
                      {departments?.map((d) => (
                        <Select.Option
                          key={d.hisDepartmentId}
                          value={d.hisDepartmentId}
                        >
                          {d.hisDepartmentName}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Button type='primary' onClick={form.submit}>
                    查询
                  </Button>
                </Space>
                <Space>
                <Button
              type='primary'
              onClick={() => {
                exportList()
              }}
            >
              导出
            </Button>
                </Space>
              </Row>
            </Form>
            <EditableList
              rowKey={(_, i) => `${i}}`}
              page={{
                items: page.items,
                current: params.current,
                size: params.size,
                total: page.total,
              }}
              loading={pageLoading}
              columns={columns((action: any, v: any, e: any) => {
                console.log(v, e, 'xxxxxxxxxxx')
                switch (action) {
                  case 'edit':
                    setIsEditRemarkVisible(true)
                    setId(v.id)
                    break
                  case 'stateEdit':
                    dispatch(
                      getDoctorManagementUpload({
                        id: v.id,
                        flag: e,
                      })
                    )
                      .then(unwrapResult)
                      .then((v) => {
                        notification.success({
                          message: '操作成功',
                          duration: 3,
                        })
                        getPage()
                      })

                    break
                  // if (!v?.headPhoto || !v?.jobTitle) {
                  //   notification.info({ message: '请先去完善医生信息!' })
                  // } else {
                  //   dispatch(
                  //     getDoctorManagementUpload({
                  //       wechatShowFlg: Number(e),
                  //       id: v.id,
                  //     })
                  //   )
                  //     .then(unwrapResult)
                  //     .then((v) => {
                  //       notification.success({
                  //         message: '操作成功',
                  //         duration: 3,
                  //       })
                  //       getPage()
                  //     })
                  // }

                  // break
                }
              }, (action: any, v: any, e: any) => {
                switch (action) {
                  case 'edit':
                    setIsOnlineEditRemarkVisible(true)
                    setIdOnline(v.id)
                    break
                  case 'stateEdit':
                    dispatch(
                      onlineInquiryUpload({
                        id: v.id,
                        flag: e,
                      })
                    )
                      .then(unwrapResult)
                      .then((v) => {
                        notification.success({
                          message: '操作成功',
                          duration: 3,
                        })
                        getPage()
                      })

                    break
                }
              }, params.size * (params.current - 1))}
              onChangePage={(current, size) => {
                console.log(params, 'change')
                setParams({ ...params, current, size: size || 10 })
              }}
            />
          </Col>
          <EditDetailModal
            id={id}
            visible={isEditRemarkVisible}
            onCancel={() => {
              setIsEditRemarkVisible(false)
              setId('')
              getPage()
            }}
            onOk={() => {
              setIsEditRemarkVisible(false)
              setId('')
              getPage()
            }}
          />
        </Col>
      </TabPane>
    </TabBar>
  )
}

export const DoctorManagementList = forwardRef(RealDoctorManagements)
