import React, { MouseEventHandler, ReactElement } from 'react'
import { AlertOutlined } from '@ant-design/icons'
import styles from './Icons.module.css'
import { ReactComponent as AccountFilledIcon } from './svg/filled/account.svg'
import { ReactComponent as BackFilledIcon } from './svg/filled/back.svg'
import { ReactComponent as FemaleFilledIcon } from './svg/filled/female.svg'
import { ReactComponent as LogoFilledIcon } from './svg/filled/logo.svg'
import { ReactComponent as MaleFilledIcon } from './svg/filled/male.svg'
import { ReactComponent as PasswordFilledIcon } from './svg/filled/password.svg'
import { ReactComponent as RefreshFilledIcon } from './svg/filled/refresh.svg'
import { ReactComponent as TenantFilledIcon } from './svg/filled/tenant.svg'
import { ReactComponent as TreatmentFilledIcon } from './svg/filled/treatment.svg'
import { ReactComponent as AccountOutlinedIcon } from './svg/outlined/account.svg'
import { ReactComponent as ApointmentOutlinedIcon } from './svg/outlined/appointment.svg'
import { ReactComponent as BackOutlinedIcon } from './svg/outlined/back.svg'
import { ReactComponent as HomeOutlineIcon3 } from './svg/outlined/chuzhen.svg'
import { ReactComponent as HomeOutlineIcon2 } from './svg/outlined/daijiezhen.svg'
import { ReactComponent as DelectOutlinedIcon } from './svg/outlined/delect.svg'
import { ReactComponent as DeleteOutlinedIcon } from './svg/outlined/delete.svg'
import { ReactComponent as DeliveryOutlinedIcon } from './svg/outlined/delivery.svg'
import { ReactComponent as DownLineOutlinedIcon } from './svg/outlined/downLineIcon.svg'
import { ReactComponent as EditOutlinedIcon } from './svg/outlined/edit.svg'
import { ReactComponent as EditsOutlinedIcon } from './svg/outlined/edits.svg'
import { ReactComponent as HomeOutlineIcon4 } from './svg/outlined/fuzhen.svg'
import { ReactComponent as HelperCenterOutlinedIcon } from './svg/outlined/helpercenter.svg'
import { ReactComponent as HomeOutlinedIcon } from './svg/outlined/home.svg'
import { ReactComponent as HomeOutlineIcon1 } from './svg/outlined/huanzhe.svg'
import { ReactComponent as HomeOutlineIcon5 } from './svg/outlined/jinriyuyue.svg'
import { ReactComponent as LogoutOutlinedIcon } from './svg/outlined/logout.svg'
import { ReactComponent as MenuOutlinedIcon } from './svg/outlined/menu.svg'
import { ReactComponent as NotificationOutlinedIcon } from './svg/outlined/notification.svg'
import { ReactComponent as StatementOutlinedIcon } from './svg/outlined/statement.svg'
import { ReactComponent as NurseStationOutlinedIcon } from './svg/outlined/nursestation.svg'
import { ReactComponent as PatientsOutlinedIcon } from './svg/outlined/patients.svg'
import { ReactComponent as PaymentOutlinedIcon } from './svg/outlined/payment.svg'
import { ReactComponent as PitestOutlinedIcon } from './svg/outlined/pitest.svg'
import { ReactComponent as PrintOutlinedIcon } from './svg/outlined/print.svg'
import { ReactComponent as RegistrationOutlinedIcon } from './svg/outlined/registration.svg'
import { ReactComponent as RetailOutlinedIcon } from './svg/outlined/retail.svg'
import { ReactComponent as ReverseOutlinedIcon } from './svg/outlined/reverse.svg'
import { ReactComponent as SalesOutlinedIcon } from './svg/outlined/sales.svg'
import { ReactComponent as SettingOutlinedIcon } from './svg/outlined/setting.svg'
import { ReactComponent as HomeOutlineIcon6 } from './svg/outlined/suifang.svg'
import { ReactComponent as TenantOutlinedIcon } from './svg/outlined/tenant.svg'
import { ReactComponent as TenantManagerOutlinedIcon } from './svg/outlined/tenantmanager.svg'
import { ReactComponent as TreatmentOutlinedIcon } from './svg/outlined/treatment.svg'
import { ReactComponent as UpgradeOutlinedIcon } from './svg/outlined/upgrade.svg'
import { ReactComponent as UserHomeOutlinedIcon } from './svg/outlined/userhome.svg'
import { ReactComponent as WarehouseStationOutlinedIcon } from './svg/outlined/warehousestation.svg'
import { ReactComponent as SparePartsOutlinedIcon } from './svg/outlined/spareparts.svg'
import { ReactComponent as RegularOutlinedIcon } from './svg/outlined/Regular.svg'
import { ReactComponent as TriageOutlinedIcon } from './svg/outlined/triage.svg'
import { ReactComponent as PersonalManageOutlinedIcon } from './svg/outlined/personalManage.svg'
import { ReactComponent as ShoperOutlinedIcon } from './svg/outlined/shoper.svg'
import { ReactComponent as ReadyAppealIcon } from './svg/filled/readyAppeal.svg'
import { ReactComponent as AppealInIcon } from './svg/filled/appealIn.svg'
import { ReactComponent as AppealOverIcon } from './svg/filled/appealOver.svg'
import { ReactComponent as AppealAllIcon } from './svg/filled/appealAll.svg'
import { ReactComponent as CloseIcon } from './svg/filled/close.svg'
import { ReactComponent as FaceLoginIcon } from './svg/filled/faceLogin.svg'
import { ReactComponent as ReplaceMakeCenter } from './svg/outlined/replaceMakeCenter.svg'
import { ReactComponent as Partner } from './svg/outlined/partner.svg'
import { ReactComponent as Medical } from './svg/outlined/medical.svg'
import { ReactComponent as PenNumberIcon } from './svg/outlined/penNumber.svg'
import { ReactComponent as InstitutionsIcon } from './svg/outlined/institutions.svg'
import { ReactComponent as ActiveInstitutionsIcon } from './svg/outlined/activeInstitutions.svg'
import { ReactComponent as HealthCareIcon } from './svg/outlined/healthCare.svg'
import { ReactComponent as PayAgencyIcon } from './svg/outlined/payAgency.svg'
import { ReactComponent as LossInstitutionsIcon } from './svg/outlined/lossInstitutions.svg'
import { ReactComponent as DoctorIcon } from './svg/outlined/doctor.svg'
import { ReactComponent as PatientsNumberIcon } from './svg/outlined/patientsNumber.svg'
import { ReactComponent as SeeDoctorIcon } from './svg/outlined/seeDoctor.svg'
import { ReactComponent as MoneyIcon } from './svg/outlined/money.svg'
import { ReactComponent as RecipeNumIcon } from './svg/outlined/recipeNum.svg'
import { ReactComponent as MedicalRecordNumIcon } from './svg/outlined/medicalRecordNum.svg'
import { ReactComponent as UniversalMedicalRecordNumIcon } from './svg/outlined/universalMedicalRecordNum.svg'
import { ReactComponent as TcmMedicalRecordNumIcon } from './svg/outlined/tcmMedicalRecordNum.svg'
import { ReactComponent as MouthMedicalRecordNumIcon } from './svg/outlined/mouthMedicalRecordNum.svg'
import { ReactComponent as BeautyClinicMedicalRecordNumIcon } from './svg/outlined/beautyClinicMedicalRecordNum.svg'
import { ReactComponent as PriceLowIcon } from './svg/outlined/priceLow.svg'
import { ReactComponent as PushpinIcon } from './svg/outlined/pushpin.svg'
import { ReactComponent as FreePatientIcon } from './svg/oneIcon/freePatient.svg'
import { ReactComponent as JKKPIcon } from './svg/oneIcon/jkkp.svg'
import { ReactComponent as YYGLIcon } from './svg/oneIcon/yygl.svg'
import { ReactComponent as ChecklistManagementIcon } from './svg/oneIcon/checklistManagement.svg'
import { ReactComponent as YHGLIcon } from './svg/oneIcon/yhgl.svg'
import { ReactComponent as XTGLIcon } from './svg/oneIcon/xtgl.svg'
import { ReactComponent as MemeberIcon } from './svg/oneIcon/member.svg'
import { ReactComponent as AppointmentIcon } from './svg/oneIcon/appointment.svg'
import { ReactComponent as WorkOrderIcon } from './svg/oneIcon/workOrder.svg'
import { ReactComponent as MedicalGuideWebViewListIcon } from './svg/oneIcon/medicalGuideWebViewList.svg'

type IconMode = 'large' | 'normal' | 'small'

interface IconProps {
  mode?: IconMode
  icon?: string
  className?: string
  onClick?: MouseEventHandler
  style?: React.CSSProperties
}

function getStyleName(props: IconProps): string {
  if (props.className && props.className !== 'ant-menu-item-icon') {
    return props.className
  }
  if (props.mode === 'large') {
    return styles.large
  }
  if (props.mode === 'small') {
    return styles.small
  }
  return styles.normal
}

export const AccountFilled = (props: IconProps): ReactElement => (
  <AccountFilledIcon className={getStyleName(props)} />
)

export const BackFilled = (props: IconProps): ReactElement => (
  <BackFilledIcon className={getStyleName(props)} style={props.style} />
)

export const BackOutlined = (props: IconProps): ReactElement => (
  <BackOutlinedIcon className={getStyleName(props)} />
)

export const DeleteOutlined = (props: IconProps): ReactElement => (
  <DeleteOutlinedIcon className={getStyleName(props)} style={props.style} />
)

export const EditOutlined = (props: IconProps): ReactElement => (
  <EditOutlinedIcon
    className={getStyleName(props)}
    style={props.style}
    onClick={props.onClick}
  />
)

export const DownLineOutlined = (props: IconProps): ReactElement => (
  <DownLineOutlinedIcon
    className={getStyleName(props)}
    style={props.style}
    onClick={props.onClick}
  />
)

export const FemaleFilled = (props: IconProps): ReactElement => (
  <FemaleFilledIcon className={getStyleName(props)} />
)

export const LogoFilled = (props: IconProps): ReactElement => (
  <LogoFilledIcon className={getStyleName(props)} />
)

export const LogoutOutlined = (props: IconProps): ReactElement => (
  <LogoutOutlinedIcon className={getStyleName(props)} />
)

export const MaleFilled = (props: IconProps): ReactElement => (
  <MaleFilledIcon className={getStyleName(props)} />
)

export const PasswordFilled = (props: IconProps): ReactElement => (
  <PasswordFilledIcon className={getStyleName(props)} />
)

export const RefreshFilled = (props: IconProps): ReactElement => (
  <RefreshFilledIcon
    className={getStyleName(props)}
    style={props.style}
    onClick={props.onClick}
  />
)

export const TenantFilled = (props: IconProps): ReactElement => (
  <TenantFilledIcon className={getStyleName(props)} />
)

export const TenantOutlined = (props: IconProps): ReactElement => (
  <TenantOutlinedIcon className={getStyleName(props)} />
)

export const TreatmentFilled = (props: IconProps): ReactElement => (
  <TreatmentFilledIcon className={getStyleName(props)} style={props.style} />
)

export const UpgradeOutlined = (props: IconProps): ReactElement => (
  <UpgradeOutlinedIcon className={getStyleName(props)} />
)

export const UserHomeOutlined = (props: IconProps): ReactElement => (
  <UserHomeOutlinedIcon className={getStyleName(props)} />
)
export const UserHomeOutlined1 = (props: IconProps): ReactElement => (
  <HomeOutlineIcon1 className={getStyleName(props)} style={props.style} />
)
export const UserHomeOutlined2 = (props: IconProps): ReactElement => (
  <HomeOutlineIcon2 className={getStyleName(props)} style={props.style} />
)
export const UserHomeOutlined3 = (props: IconProps): ReactElement => (
  <HomeOutlineIcon3 className={getStyleName(props)} style={props.style} />
)
export const UserHomeOutlined4 = (props: IconProps): ReactElement => (
  <HomeOutlineIcon4 className={getStyleName(props)} style={props.style} />
)
export const UserHomeOutlined5 = (props: IconProps): ReactElement => (
  <HomeOutlineIcon5 className={getStyleName(props)} style={props.style} />
)
export const UserHomeOutlined6 = (props: IconProps): ReactElement => (
  <HomeOutlineIcon6 className={getStyleName(props)} style={props.style} />
)

export const PitestOutlined = (props: IconProps): ReactElement => (
  <PitestOutlinedIcon className={getStyleName(props)} style={props.style} />
)
export const EditsOutlined = (props: IconProps): ReactElement => (
  <EditsOutlinedIcon
    className={getStyleName(props)}
    style={props.style}
    onClick={props.onClick}
  />
)
export const DelectOutlined = (props: IconProps): ReactElement => (
  <DelectOutlinedIcon
    className={getStyleName(props)}
    style={props.style}
    onClick={props.onClick}
  />
)
export const SettingOutlined = (props: IconProps): ReactElement => (
  <SettingOutlinedIcon className={getStyleName(props)} style={props.style} />
)
export const AccountOutlined = (props: IconProps): ReactElement => (
  <AccountOutlinedIcon className={getStyleName(props)} style={props.style} />
)
export const ReverseOutlined = (props: IconProps): ReactElement => (
  <ReverseOutlinedIcon className={getStyleName(props)} style={props.style} />
)
export const PrintOutlined = (props: IconProps): ReactElement => (
  <PrintOutlinedIcon className={getStyleName(props)} style={props.style} />
)

export const SparePartsOutlined = (props: IconProps): ReactElement => (
  <SparePartsOutlinedIcon className={getStyleName(props)} style={props.style} />
)

export const RegularOutlined = (props: IconProps): ReactElement => (
  <RegularOutlinedIcon className={getStyleName(props)} style={props.style} />
)
export const PersonalManagementOutlinedIcon = (
  props: IconProps
): ReactElement => (
  <PersonalManageOutlinedIcon
    className={getStyleName(props)}
    style={props.style}
  />
)
export const ShoperOutlined = (props: IconProps): ReactElement => (
  <ShoperOutlinedIcon className={getStyleName(props)} style={props.style} />
)

export const AppealReady = (props: IconProps): ReactElement => (
  <ReadyAppealIcon className={getStyleName(props)} style={props.style} />
)

export const AppealOver = (props: IconProps): ReactElement => (
  <AppealOverIcon className={getStyleName(props)} style={props.style} />
)
export const AppealIn = (props: IconProps): ReactElement => (
  <AppealInIcon className={getStyleName(props)} style={props.style} />
)
export const AppealAll = (props: IconProps): ReactElement => (
  <AppealAllIcon className={getStyleName(props)} style={props.style} />
)
export const CloseFaceLoginLine = (props: IconProps): ReactElement => (
  <CloseIcon className={getStyleName(props)} style={props.style} />
)
export const FaceLoginLine = (props: IconProps): ReactElement => (
  <FaceLoginIcon className={getStyleName(props)} style={props.style} />
)
export const PenNumberLine = (props: IconProps): ReactElement => (
  <PenNumberIcon className={getStyleName(props)} style={props.style} />
)
export const InstitutionsLine = (props: IconProps): ReactElement => (
  <InstitutionsIcon className={getStyleName(props)} style={props.style} />
)
export const ActiveInstitutionsLine = (props: IconProps): ReactElement => (
  <ActiveInstitutionsIcon className={getStyleName(props)} style={props.style} />
)
export const HealthCareLine = (props: IconProps): ReactElement => (
  <HealthCareIcon className={getStyleName(props)} style={props.style} />
)
export const PayAgencyLine = (props: IconProps): ReactElement => (
  <PayAgencyIcon className={getStyleName(props)} style={props.style} />
)
export const LossInstitutionsLine = (props: IconProps): ReactElement => (
  <LossInstitutionsIcon className={getStyleName(props)} style={props.style} />
)
export const DoctorLine = (props: IconProps): ReactElement => (
  <DoctorIcon className={getStyleName(props)} style={props.style} />
)
export const PatientsNumberLine = (props: IconProps): ReactElement => (
  <PatientsNumberIcon className={getStyleName(props)} style={props.style} />
)
export const SeeDoctorLine = (props: IconProps): ReactElement => (
  <SeeDoctorIcon className={getStyleName(props)} style={props.style} />
)
export const MoneyLine = (props: IconProps): ReactElement => (
  <MoneyIcon className={getStyleName(props)} style={props.style} />
)
export const RecipeNumLine = (props: IconProps): ReactElement => (
  <RecipeNumIcon className={getStyleName(props)} style={props.style} />
)
export const MedicalRecordNumLine = (props: IconProps): ReactElement => (
  <MedicalRecordNumIcon className={getStyleName(props)} style={props.style} />
)
export const UniversalMedicalRecordNumLine = (
  props: IconProps
): ReactElement => (
  <UniversalMedicalRecordNumIcon
    className={getStyleName(props)}
    style={props.style}
  />
)
export const TcmMedicalRecordNumLine = (props: IconProps): ReactElement => (
  <TcmMedicalRecordNumIcon
    className={getStyleName(props)}
    style={props.style}
  />
)
export const MouthMedicalRecordNumLine = (props: IconProps): ReactElement => (
  <MouthMedicalRecordNumIcon
    className={getStyleName(props)}
    style={props.style}
  />
)
export const BeautyClinicMedicalRecordNumLine = (
  props: IconProps
): ReactElement => (
  <BeautyClinicMedicalRecordNumIcon
    className={getStyleName(props)}
    style={props.style}
  />
)
export const PriceLowLine = (props: IconProps): ReactElement => (
  <PriceLowIcon className={getStyleName(props)} style={props.style} />
)
export const PushpinLine = (props: IconProps): ReactElement => (
  <PushpinIcon className={getStyleName(props)} style={props.style} />
)

export const UploadOutlined = (props: IconProps): ReactElement => (
  <UpgradeOutlinedIcon className={getStyleName(props)} />
)

export const FreePatientLineIcon = (props: IconProps): ReactElement => (
  <FreePatientIcon className={getStyleName(props)} style={props.style} />
)
export const JKKPLineIcon = (props: IconProps): ReactElement => (
  <JKKPIcon className={getStyleName(props)} style={props.style} />
)
export const YYGLLineIcon = (props: IconProps): ReactElement => (
  <YYGLIcon className={getStyleName(props)} style={props.style} />
)
export const ChecklistManagementLineIcon = (props: IconProps): ReactElement => (
  <ChecklistManagementIcon
    className={getStyleName(props)}
    style={props.style}
  />
)
export const YYGLLineIconLineIcon = (props: IconProps): ReactElement => (
  <YYGLLineIcon className={getStyleName(props)} style={props.style} />
)

export const YHGLLineIcon = (props: IconProps): ReactElement => (
  <YHGLIcon className={getStyleName(props)} style={props.style} />
)

export const XTGLLineIcon = (props: IconProps): ReactElement => (
  <XTGLIcon className={getStyleName(props)} style={props.style} />
)

export const MemeberLineIcon = (props: IconProps): ReactElement => (
  <MemeberIcon className={getStyleName(props)} style={props.style} />
)

export const AppointmentLineIcon = (props: IconProps): ReactElement => (
  <AppointmentIcon className={getStyleName(props)} style={props.style} />
)

export const WorkOrderLineIcon = (props: IconProps): ReactElement => (
  <WorkOrderIcon className={getStyleName(props)} style={props.style} />
)

export const MedicalGuideWebViewListLineIcon = (props: IconProps): ReactElement => (
  <MedicalGuideWebViewListIcon className={getStyleName(props)} style={props.style} />
)


export const Icon = (props: IconProps): ReactElement => {
  switch (props.icon) {
    case 'freePatient':
      return (
        <FreePatientLineIcon
          className={getStyleName(props)}
          style={props.style}
        />
      )
    case 'jkkp':
      return (
        <JKKPLineIcon className={getStyleName(props)} style={props.style} />
      )
    case 'yygl':
      return (
        <YYGLLineIcon className={getStyleName(props)} style={props.style} />
      )
    case 'checklistManagement':
      return (
        <ChecklistManagementLineIcon
          className={getStyleName(props)}
          style={props.style}
        />
      )
    case 'yhgl':
      return (
        <YHGLLineIcon className={getStyleName(props)} style={props.style} />
      )
    case 'xtgl':
      return (
        <XTGLLineIcon className={getStyleName(props)} style={props.style} />
      )
    case 'member':
      return (
        <MemeberLineIcon className={getStyleName(props)} style={props.style} />
      )
    case 'appointment':
      return (
        <AppointmentLineIcon
          className={getStyleName(props)}
          style={props.style}
        />
      )

    case 'gdzx':
      return (
        <WorkOrderLineIcon
          className={getStyleName(props)}
          style={props.style}
        />
      )
    case 'medicalGuideWebViewList':
      return (
        <MedicalGuideWebViewListLineIcon
          className={getStyleName(props)}
          style={props.style}
        />
      )
  }
  return (
    <HelperCenterOutlinedIcon
      className={getStyleName(props)}
      style={props.style}
    />
  )
}

export default Icon
