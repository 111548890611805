/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-03-16 17:16:18
 * @LastEditors: linxi
 * @LastEditTime: 2023-03-16 17:20:42
 */
import { unwrapResult } from '@reduxjs/toolkit'
import { Button, Col, Form, Modal, notification, Steps } from 'antd'
import React, {
  forwardRef,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useDispatch } from 'react-redux'
import { RootDispatch } from '../../app/store'
import { ThemeContext } from '../../theme/ThemeContext'
import { Query } from './Query'
import { getMedicalGuideWebViewList } from './medicalGuideWebViewListSlice'

const ReaMedicalGuideWebView = (): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const theme = useContext(ThemeContext)

  const { Step } = Steps

  const [pageLoading, setPageLoading] = useState(false) //表格 loading

  const [data, setData] = useState<any>()

  const [params, setParams] = useState<any>({
    searchType: '1',
    searchCode: '',
    queryType: '1',
  })

  const getPage = () => {
    setPageLoading(true)
    dispatch(
      getMedicalGuideWebViewList({
        ...params,
        searchType: params?.searchType ? params?.searchType : '1',
        queryType: params?.queryType ? params?.queryType : '1',
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        if (JSON.stringify(res) == '{}') {
          setData([])
        } else {
          setData(res)
        }
      })
      .finally(() => setPageLoading(false))
  }

  useEffect(() => {
    if (params?.searchCode) {
      getPage()
    } else {
      notification.info({
        message:
          params?.searchType == '2' ? '请输入处方号查询' : '请输入手机号查询',
        duration: 3,
      })
      setData([])
    }
  }, [params])

  return (
    <Col
      style={{
        height: 'calc(100% - 20px)',
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
        margin: ' 10px',
        flexDirection: 'column',
      }}
    >
      <Col
        style={{
          flex: 1,
          backgroundColor: theme.pn,
          borderRadius: 10,
          padding: '15px  15px 0 15px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Query
          onValueChange={(v: any) => {
            setParams({
              ...params,
              ...v,
            })
          }}
        />
        <div
          style={{
            marginTop: '20px',
            height: 'calc(100% - 80px)',
            overflow: 'auto',
          }}
        >
          {data?.deliveryInfo?.length ? (
            <>
              <Steps
                size='small'
                current={data?.deliveryInfo?.length}
                direction='vertical'
              >
                {data?.deliveryInfo?.map((v: any, index: any) => {
                  return (
                    <Step title={v?.state} description={v?.time} key={index} />
                  )
                })}
              </Steps>
            </>
          ) : (
            <div
              style={{
                marginTop:'200px' ,
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              暂无数据
            </div>
          )}
        </div>
      </Col>
    </Col>
  )
}

export const ReaMedicalGuideWebViewList = forwardRef(ReaMedicalGuideWebView)
