
/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-03-17 16:02:20
 * @LastEditors: linxi
 * @LastEditTime: 2023-03-17 16:02:20
 */

import { createAsyncThunk } from '@reduxjs/toolkit'
import { RootThunkApi } from '../../app/store'
import { sendAsync } from '../../app/applicationSlice'
import * as DT from '../../services/medicalGuideWebViewList'

// 列表
export const getMedicalGuideWebViewList = createAsyncThunk<
  void,
  DT.MedicalGuideWebViewListParams,
  RootThunkApi<void>
>('clinic/getMedicalGuideWebViewList', async (params, api) => {
  return sendAsync(DT.MedicalGuideWebViewList(params), api)
})
