/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-03-17 16:00:30
 * @LastEditors: linxi
 * @LastEditTime: 2023-03-17 16:00:31
 */
import { Button, Col, Tabs, Row, Form, Space, Input, Select } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import React, { ReactElement, useEffect, useState } from 'react'
import { ChecklistManagementPrams } from '../../services/checklistManagement'

export const Query = (props: {
  onValueChange: (queries: ChecklistManagementPrams) => void
}): ReactElement => {
  const [form] = Form.useForm()
  const { Option } = Select

  const [textPlaceHolder, setTextPlaceHolder] = useState('手机号')

  useEffect(() => {
    form.setFieldsValue({
      searchType: '1',
    })
    setTextPlaceHolder('手机号')
  }, [])

  return (
    <>
      <Form
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
        form={form}
        onFinish={(vs) => props.onValueChange(vs)}
      >
        <Row
          style={{ marginBottom: 10, width: '100%' }}
          align='middle'
          justify='space-between'
        >
          <Space>
            <Form.Item name='searchType' noStyle>
              <Select
                defaultValue={'1'}
                placeholder='请选择方式'
                style={{ width: 140 }}
                onChange={(v:any) => {
                  // form.submit()
                  if(v == '2'){
                    setTextPlaceHolder('处方号')
                  }else{
                    setTextPlaceHolder('手机号')
                  }
                }}
              >
                <Option value={'1'} key={'1'}>
                  手机号
                </Option>
                <Option value={'2'} key={'2'}>
                  处方号
                </Option>
              </Select>
            </Form.Item>
            <Form.Item name='searchCode' noStyle>
              <Input
                autoComplete='off'
                placeholder={textPlaceHolder}
                style={{ width: 150 }}
                // onChange={() => form.submit()}
                prefix={<SearchOutlined />}
                allowClear
              />
            </Form.Item>
            <Form.Item name='queryType' noStyle>
              <Select
                defaultValue={'1'}
                placeholder='请选择方式'
                style={{ width: 140 }}
                // onChange={() => form.submit()}
              >
                <Option value={'1'} key={'1'}>
                  煎配信息
                </Option>
                <Option value={'2'} key={'2'}>
                  物流信息
                </Option>
              </Select>
            </Form.Item>
            <Button type='primary' onClick={() => form.submit()}>
              查询
            </Button>
          </Space>
        </Row>
      </Form>
    </>
  )
}
